const initializeSortables = (root) => {
  const sortables = root.querySelectorAll('.sortable-container:not([data-initialized="true"])');

  sortables.forEach((sortable) => {
    sortable.setAttribute('data-initialized', 'true');
    // eslint-disable-next-line
    new SortableList(sortable);
  });
};

(async () => {
  if (typeof window === 'undefined') return;

  document.addEventListener('DOMContentLoaded', () => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof Element) {
              initializeSortables(node);
            }
          });
        }
      });
    });

    observer.observe(document.documentElement, { childList: true, subtree: true });
    initializeSortables(document);
  });
})();

class SortableList {
  constructor(sl) {
    this.sortableList = sl.querySelector('.sortable-list');
    this.hiddenInput = sl.querySelector('.sort-order-hidden-input');
    this.listItems = sl.querySelectorAll('.sortable-list-item');

    this.addEventListeners();
    this.initializeSortable();
  }

  initializeSortable = async () => {
    const { Sortable, Plugins } = await import('@shopify/draggable');

    const sortable = new Sortable(this.sortableList, {
      draggable: '.sortable-list-item',
      mirror: {
        appendTo: '.sortable-list',
        constrainDimensions: true
      },
      plugins: [Plugins.SortAnimation],
      sortAnimation: {
        duration: 200,
        easingFunction: 'ease-in-out'
      }
    });

    sortable.on('sortable:stop', () => {
      this.updateHiddenInput();
    });

    this.initializeList();
  };

  initializeList = () => {
    this.hiddenInput.value = Array.from(this.listItems)
      .map((item) => item.getAttribute('data-value'))
      .join('\r\n');
  };

  addEventListeners = () => {
    this.listItems.forEach((item) => {
      item.addEventListener('keydown', (event) => {
        if (event.key !== 'Tab') {
          event.preventDefault();
          event.stopPropagation();
        }

        if (event.key === 'ArrowDown' && item.nextElementSibling) {
          item.parentNode.insertBefore(item.nextElementSibling, item);
          item.focus();
          this.updateHiddenInput();
        }

        if (event.key === 'ArrowUp' && item.previousElementSibling) {
          item.parentNode.insertBefore(item, item.previousElementSibling);
          item.focus();
          this.updateHiddenInput();
        }
      });
    });
  };

  updateHiddenInput = () => {
    const sortedItems = Array.from(this.sortableList.children)
      .filter(
        (item) =>
          !item.classList.contains('draggable--original') &&
          !item.classList.contains('draggable-mirror')
      )
      .map((item) => item.getAttribute('data-value'));

    this.hiddenInput.value = sortedItems.join('\r\n');
  };
}
