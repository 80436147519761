import { apiGetService } from './baseApi.service';

export default function getSearch(
  query,
  page,
  itemsPerPage,
  isWhiteLabel,
  materialLibraryDescriptor,
  topics,
  formats,
  audiences,
  languages,
  states
) {
  return (async () => {
    let url = `/api/materialsearch/search?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query) {
      url += `&query=${query}`;
    }

    if (isWhiteLabel) {
      url += `&isWhiteLabel=${isWhiteLabel}`;
    }

    if (materialLibraryDescriptor) {
      url += `&materialLibraryDescriptor=${materialLibraryDescriptor}`;
    }

    if (topics) {
      url += `&topics=${topics}`;
    }

    if (formats) {
      url += `&formats=${formats}`;
    }

    if (audiences) {
      url += `&audiences=${audiences}`;
    }

    if (languages) {
      url += `&languages=${languages}`;
    }

    if (states) {
      url += `&states=${states}`;
    }

    return apiGetService(url);
  })();
}
