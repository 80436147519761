import classNames from 'classnames';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

const CCPABanner = ({ ccpaBanner, closeCCPABanner }) => {
  const [, setCookie] = useCookies(['CCPABanner']);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => {
    setCookie('CCPABanner', null, {
      path: '/',
      expires: new Date(new Date().getFullYear() + 10, 0, 1)
    });
    setVisible(false);
    closeCCPABanner();
  };

  return (
    <section
      className={classNames('ccpa-section section-main border-top fixed full-width', {
        closed: !visible
      })}
    >
      <div className="container-fluid">
        <div className="ccpa-banner">
          <div dangerouslySetInnerHTML={{ __html: ccpaBanner.text }} />
          <button className="btn btn-primary-alt" type="button" onClick={() => onDismiss()}>
            Accept
          </button>
        </div>
      </div>
    </section>
  );
};

export default CCPABanner;
