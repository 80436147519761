import React from 'react';

const MaterialListing = ({
  audience,
  materialDescription,
  formID,
  format,
  materialFile,
  materialThumbnail,
  materialTitle,
  revisionDate,
  isWhiteLabel,
  whiteLabelVersions
}) => {
  const showWhiteLabelVersions = isWhiteLabel && whiteLabelVersions?.length > 0;
  const date = new Date(revisionDate).toLocaleDateString(undefined, {
    year: '2-digit',
    month: 'numeric',
    timeZone: 'UTC'
  });
  return (
    <div
      className="material-listing"
      key={materialTitle}
      data-analytics-identifier={`${formID}|${materialTitle}`}
    >
      <a href={materialFile} target="_blank" rel="noopener noreferrer">
        <img className="image" src={materialThumbnail} alt={materialTitle} />
      </a>
      <div className="content">
        <a href={materialFile} target="_blank" rel="noopener noreferrer">
          <h3>{materialTitle}</h3>
        </a>
        {formID && <p className="bold">{`Form #${formID}`}</p>}
        {audience && <p>Audience: {audience.join(', ')}</p>}
        {format && <p>Format: {format}</p>}
        {materialDescription && <p>{materialDescription}</p>}
        {showWhiteLabelVersions &&
          whiteLabelVersions.map((version) => (
            <div key={version.fileTitle + version.fileName}>
              <p className="bold">
                {version.fileTitle}:{' '}
                <a href={version.directUrl} target="_blank" rel="noopener noreferrer">
                  {version.fileName}
                </a>
              </p>
            </div>
          ))}
        <p className="italics">Updated: {date}</p>
      </div>
    </div>
  );
};

export default MaterialListing;
