import React from 'react';
import classNames from 'classnames';
import { getSectionClassNames } from '@/utilities/component-color';
import Statistic from './Statistic';

const StatsCycleGroupComponent = ({ statsCycleGroup, componentBackgroundColor }) => {
  const { header, description, statistics } = statsCycleGroup;

  return (
    <section className={classNames('stats-cycle', getSectionClassNames(componentBackgroundColor))}>
      <div className="container-fluid">
        <div className="stats-cycle-info">
          <h2>{header}</h2>
          <p>{description}</p>
        </div>
        <div className="stats-cycle-statistics">
          {statistics.map((statistic) => (
            <Statistic
              key={statistic.statName}
              title={statistic.statName}
              value={statistic.statValue}
              icon={statistic.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatsCycleGroupComponent;
