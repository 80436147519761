import React from 'react';

const SitemapLanding = ({ headerNav, otherNavItems, searchUrl }) => (
  <div className="sitemap-landing section-white">
    <div className="container-fluid">
      {headerNav?.map((megaMenuItem) => (
        <section key={megaMenuItem.name}>
          <h2>{megaMenuItem.name}</h2>
          <ul>
            {megaMenuItem.navigationChildren?.length ? (
              megaMenuItem.navigationChildren.map((menuItem) => (
                <li key={menuItem.path + menuItem.name}>
                  <a href={menuItem.path}>{menuItem.name}</a>
                </li>
              ))
            ) : (
              <li>
                <a href={megaMenuItem.path}>{megaMenuItem.name}</a>
              </li>
            )}
          </ul>
        </section>
      ))}
      <section>
        <h2>Other</h2>
        <ul>
          {otherNavItems?.map((menuItem) => (
            <li key={menuItem.path + menuItem.name}>
              <a href={menuItem.path}>{menuItem.name}</a>
            </li>
          ))}
          <li>
            <a href={searchUrl}>Search</a>
          </li>
        </ul>
      </section>
    </div>
  </div>
);

export default SitemapLanding;
